import React from "react"
import Header from "./header"
import Footer from "./footer"

import "../styles/styles.scss"

export default props => (
  <div className={"page"}>
    <Header />
    <div className={"content"}>
      {props.children}
    </div>
    <Footer />
  </div>
)
