import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faXingSquare } from "@fortawesome/free-brands-svg-icons"
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const SocialLink = props => (
  <a href={props.link} title={props.title} className={props.iconClassName} target={"_blank"}
     rel={"noopener noreferrer"}>
    <FontAwesomeIcon icon={props.icon} size={"3x"}/>
  </a>
)

const ReferenceCol = props => (
  <Col className={"reference-col"} md={6} xs={12}>
    <a href={props.link} title={props.title} target={"_blank"} rel={"noopener noreferrer"}>
      <Img fixed={props.image} objectFit={"contain"} style={{ display: "block", width: "100%", height: "100%" }}/>
    </a>
  </Col>
)

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        dvnlpImage: file(relativePath: { eq: "dvnlp.png" }) {
          childImageSharp {
            fixed(width: 237, height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        kampfkunstKollegiumImage: file(relativePath: { eq: "kampfkunst-kollegium.png" }) {
          childImageSharp {
            fixed(width: 321, height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <div className="footer angled-top">
        <div className={"footer-content"}>
          <Container>
            <h2>Kontaktieren Sie mich</h2>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <p>
                  Sie sind interessiert, haben Fragen, benötigen ein Angebot oder möchten einfach nur Hallo sagen?
                  Senden Sie mir eine E-Mail!
                </p>
                <div className={"contact"}>
                  <a href={"mailto:hallo@dorakami.de"} className={"mail"}>
                    <FontAwesomeIcon icon={faEnvelopeSquare} title={"E-Mail senden"} size={"3x"}/>
                  </a>
                  <SocialLink link={"https://www.xing.com/profile/Christoph_Berndt6"} icon={faXingSquare}
                              title={"Xing Profil"} iconClassName={"xing"}/>
                  <SocialLink link={"https://www.linkedin.com/in/berndt-christoph/"} icon={faLinkedin}
                              title={"LinkedIn Profil"} iconClassName={"linkedin"}/>
                </div>
              </Col>
              <Col className={"references"}>
                <Row>
                  <ReferenceCol link={"https://www.dvnlp.de/"} title={"DVNLP"}
                                mobilePrimary={false} image={data.dvnlpImage.childImageSharp.fixed}/>
                  <ReferenceCol link={"https://www.kampfkunstkollegium.com/"} title={"Kampfkunst Kollegium"}
                                mobilePrimary={true} image={data.kampfkunstKollegiumImage.childImageSharp.fixed}/>
                </Row>
              </Col>
            </Row>
            <div className={"bottom-links"}>
              <span className={"copyright"}>Copyright &copy; {new Date().getFullYear()} Christoph Berndt</span>
              <div className={"links"}>
                <Link to={"/impressum"}>Impressum</Link>
                <Link to={"/datenschutz"}>Datenschutzerklärung</Link>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )}
  />
)

export default Footer
