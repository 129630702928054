import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Container } from "react-bootstrap"
import Navigation from "./navigation"

import logoDesktop from "../assets/logo/dorakami-coaching.png"
import logoMobile from "../assets/logo/dorakami-coaching-mobile.png"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80, srcSetBreakpoints: [1920]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <div className="header angled-bottom">
        {/* Set the aspect ratio to 0 - this does not actually change the image,
        but remove the hidden div with bottom padding */}
        <Img className="hero-image" fluid={{ ...data.heroImage.childImageSharp.fluid, aspectRatio: 0 }}
             style={{ position: "initial" }}/>
        <div className="hero-gradient"/>

        <Navigation/>

        <Container className="logo">
          <div className={"logo-image"}>
            <Link className={"logo-link"} to={"/"} title={"Startseite"}>
              <picture>
                <source media="(min-width: 576px)" srcSet={logoDesktop}/>
                <source media="(max-width: 575px)" srcSet={logoMobile}/>
                <img src={logoDesktop}
                     alt="Dorakami Coaching: Führungskräfte-Coaching, Business-Coaching und Einzel-Coaching"/>
              </picture>
            </Link>
          </div>
          <span className="slogan">Kampfkunst meets Leadership</span>
        </Container>
      </div>
    )}
  />
)

export default Header
