import React from "react"
import { Helmet } from "react-helmet"
import { graphql, StaticQuery } from "gatsby"

const Meta = props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            siteName
            siteUrl
            author
            siteVerification {
              google
            }
          }
        }
      }
    `}
    render={data => (
      <Helmet title={props.title} titleTemplate={`%s | ${data.site.siteMetadata.title}`}>
        {props.description && <meta name={"description"} content={props.description}/>}
        {props.noindex ? <meta name={"robots"} content={"noindex"}/> :
          <meta name={"robots"} content={"index, follow"}/>}

        <meta name={"og:type"} content={"website"}/>
        <meta name={"og:locale"} content={"de_DE"}/>
        <meta name={"og:site_name"} content={data.site.siteMetadata.siteName}/>
        <meta name={"og:title"} content={props.title}/>
        {props.description && <meta name={"og:description"} content={props.description}/>}

        {props.siteVerification && props.siteVerification.google &&
        <meta name={"google-site-verification"} content={props.siteVerification.google}/>}
      </Helmet>
    )}
  />
)

export default Meta
