import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const Navigation = () => (
  <div className="navigation">
    <Navbar collapseOnSelect expand="sm" variant="dark">
    <Navbar.Toggle aria-controls="dorakami-navbar-nav" label={"Navigation umschalten"}>
      <FontAwesomeIcon icon={faBars} size={"2x"}/>
    </Navbar.Toggle>
    <Navbar.Collapse id="dorakami-navbar-nav">
      <span className={"navigation-hint d-block d-sm-none"}>Navigation</span>
      <Nav className="mr-auto" as="ul">
        <Nav.Item as="li">
          <Link className="nav-link" to="/">Startseite</Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link" to="/person">Wer ich bin</Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link" to="/leistungen">Was ich anbiete</Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
</div>
)

export default Navigation
